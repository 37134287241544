.qr-side {
  display: flex;
  justify-content: center;
}

.button-section {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.pay-button {
  display: inline-block;
  background-color: #270082;
  color: white;
  width: 100%;
  height: 70px;
  font-size: 20px;
  padding: 20px;
  border-radius: 17px;
  border: none;
}
